////
/// Basic stylings for typographic elements
/// @group elements
/// @author Kristian Cox
////

::selection {
    background: $color-primary;
    color: #fff;
}
::-moz-selection {
    background: $color-primary;
    color: #fff;
}

* {
    font-size:   $font-size-base;
    line-height: 1.5;
    font-family: $font-secondary;
}

h1,
h2,
h3,
h4,
h5,
h6,
{
    font-family: $font-primary;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
table,
blockquote,
pre {
    margin: 0 0 1rem;
}

%h1-style {
    font-size:   2.2rem;
    line-height: 3rem;
    margin:      0 0 1.8rem;
}

%h2-style {
    font-size:   1.8rem;
    line-height: 2.2rem;
    margin:      0 0 2.2rem;
}

%h3-style {
    font-size:   1.6rem;
    line-height: 1.2rem;
    margin:      0 0 2.2rem;
}

%h4-style {
    font-size:   1.4rem;
    line-height: 1.2rem;
    margin:      0 0 2.2rem;
}

%h5-style {
    font-size:   1.2rem;
    line-height: 1.2rem;
    margin:      0 0 2.2rem;
}

%h6-style {
    font-size:   1rem;
    line-height: 2rem;
    margin:      0 0 2rem;
}

h1, .h1-style { @extend %h1-style; }
h2, .h2-style { @extend %h2-style; }
h3, .h3-style { @extend %h3-style; }
h4, .h4-style { @extend %h4-style; }
h5, .h5-style { @extend %h5-style; }
h6, .h6-style { @extend %h6-style; }

p {
    line-height: $line-height-base;
    margin:      0 0 $line-height-base;
    color:       #333333;
}

a {
    text-decoration: none;
    color:           $color-primary;

    &:hover {
        text-decoration: underline;
    }

    &:visited {
        // color: darken($color-primary, 10%);
    }
}

.link--success {
    color: $color-success;
}

.link--danger {
    color: $color-danger;
}

small {
    font-size: 0.75rem;
}
