.float-right {
    @extend %clearfix;
    > * {
        float: right;
    }
}

.float-left {
    @extend %clearfix;
    > * {
        float: left;
    }
}
