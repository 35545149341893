////
/// Styling for uploader
/// @group components
/// @author Kristian Cox
////

.uploader {
    padding: 1rem;
    margin:  1rem;
    border:  2px $color-primary dashed;
    text-align: center;
    position: relative;
    background: rgba($color-primary, 0);

    &__text {
        color:      $color-primary;
        display:    block;
        text-align: center;
        margin-bottom: 1rem;
    }

    &__icon {
        color:          $color-primary;
        position:       absolute;
        top:            50%;
        left:           50%;
        z-index:        100;
        transform:      translate(-50%, -50%);
        display:        none;
        pointer-events: none;
    }

    &__browse {
        @extend .button;
    }

    &--full {
        margin-left:  0rem;
        margin-right: 0rem;
    }

    &--dragging {
        animation-name:            uploader-flash;
        animation-duration:        1s;
        animation-iteration-count: infinite;

        .uploader__text,
        .uploader__browse {
            visibility: hidden;
        }
        .uploader__icon {
            display: block;
        }
    }

    &--active {
        .uploader__text,
        .uploader__browse {
            visibility: hidden;
        }
        .uploader__icon {
            display: block;
            > use {
                animation-name:            uploader-refresh;
                animation-timing-function: linear;
                animation-duration:        1s;
                animation-iteration-count: infinite;
                transform-origin:          center center;
            }
        }
    }

    &--error {
        animation-name:            uploader-error;
        animation-duration:        1s;
        animation-iteration-count: infinite;
        .uploader__text,
        .uploader__browse {
            visibility: hidden;
        }
        .uploader__icon {
            color:   $color-danger;
            display: block;
        }
    }

    &__progress {
        content:          '';
        position:         absolute;
        z-index:          0;
        background-color: rgba($color-primary, 0.2);
        top:              0;
        bottom:           0;
        left:             0;
        width:            0%;
        transition:       width 100ms linear;
    }

    input[type=file] {
        display: none;
    }
}

@keyframes uploader-flash {
    0%   {background-color: rgba($color-primary, 0);}
    50%  {background-color: rgba($color-primary, 0.2);}
    100% {background-color: rgba($color-primary, 0);}
}

@keyframes uploader-error {
    0%   {background-color: rgba($color-danger, 0);}
    50%  {background-color: rgba($color-danger, 0.4);}
    100% {background-color: rgba($color-danger, 0);}
}

@keyframes uploader-refresh {
    0%   {
        transform: rotateZ(0);
    }
    100%  {
        transform: rotateZ(-360deg);
    }
}
