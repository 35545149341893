////
/// Styling for CMS logo box
/// @group components
/// @author Kristian Cox
////

.logo {
    background: #FFFFFF;
    padding: 1rem 0;

    img {

    }

    &--desktop {
        display: none;
    }

    &--mobile {
        display: block;
        height:  1rem;
        margin:  0 auto;
    }

    @include breakpoint('lg') {
        padding: 0.5rem 0;

        &--desktop {
            display: block;
            height:  2rem;
            margin:  0 auto;
        }

        &--mobile {
            display: none;
        }
    }
    /*> div {
        display: inline-block;
        margin: 0 auto;
        @extend %clearfix;
    }
    .icon {
        padding-top: 0.5rem;
        width:       2rem;
        height:      2rem;
        color:       $color-primary;
        float: left;
    }

    @include breakpoint('lg') {
        .icon {
            margin-left: -0.5rem;
        }
        &__name {
            display: inline-block;
        }
    }*/
}
