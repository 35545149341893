////
/// Styling for the toolbar component
/// @group components
/// @author Kristian Cox
////

.toolbar {
    @extend %clearfix;

    &__search {
        display: flex;
    }

    &__input {
        &::placeholder {
            font-size:0.75rem;
        }
    }
}
