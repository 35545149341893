////
/// Styling for content area
/// @group components
/// @author Kristian Cox
////

.content {
    flex: auto;

    &__main {
        height:      100vh;
        padding-top: 4.5rem;
        box-sizing:  border-box;
        overflow-y:  auto;
    }

    &__page {
        box-sizing: border-box;
        padding:    2rem;

        @media (max-width: 480px) {
            padding: 0.5rem;
        }
    }
}
