////
/// Styling for card component
/// @group components
/// @author Kristian Cox
////

.card {
    background: $color-light;
    box-shadow: $global-box-shadow;
    box-sizing: border-box;
    color:      #666666;

    &__body {
        padding:    1rem;

        &--no-padding-bottom {
            padding-bottom: 0;
        }
    }

    &__footer {
        padding:    1rem;
        border-top: 2px solid $color-primary;
    }

    &__title {
        background: darken($color-light, 10%);
        padding:    1rem;

        > h3 {
            margin:      0;
            font-size:   1rem;
            line-height: 1rem;
        }
    }

    &__tabs {
        background: darken($color-light, 10%);

        &:after {
            display: block;
            content: "";
            clear:   both;
        }
    }

    &__tab-switch {
        position: fixed;
    }

    .card__tab-link.is-active  {
        background: $color-light;
        color:      $color-primary;

        + .card__tab-content {
            display: block;
        }
    }

    &__tab-link {
        display:         inline-block;
        padding:         1rem;
        font-size:       1rem;
        line-height:     1rem;
        text-decoration: none;
        color:           $color-dark;

        &:visited {
            color: $color-dark;
        }
    }

    &__tab-content {
        background: $color-light;
        box-sizing: border-box;
        float:      right;
        display:    none;
        width:      100%;

        &--padding {
            padding: 1rem;
        }
    }
}
