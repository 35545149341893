////
/// Styling for tags
/// @group components
/// @author Kristian Cox
////

.tag {
    font-size:   0.75rem;
    color:       #FFFFFF;
    text-align:  center;
    line-height: 1rem;
    padding:     0 1.5rem;

    &--block {
        display: block;
    }

    &--default {
        background: darken($color-light, 25%);
    }

    &--success {
        background: $color-success;
    }

    &--info {
        background: $color-info;
    }

    &--warning {
        background: $color-warning;
    }

    &--danger {
        background: $color-danger;
    }
}
