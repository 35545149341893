////
/// Styling for login form
/// @group components
/// @author Kristian Cox
////

.login {
    width:      100vw;
    height:     100vh;

    h1 {
        @extend        %h2-style;
        margin-bottom: 0;
        text-align:    center;
        color:         $color-primary;
    }

    p {
        font-size:   0.75rem;
        line-height: 1.25rem;
    }

    &__form {
        position:   absolute;
        box-sizing: border-box;
        box-shadow: $global-box-shadow;
        max-width:  300px;
        width:      100%;
        left:       50%;
        top:        50%;
        transform:  translate(-50%, -50%);
        background: $color-light;
        padding:    1rem;
    }

    &__logo {
        text-align: center;

        .icon {
            width:       10rem;
            height:      auto;
            color:       $color-primary;
            margin-top:  -5rem;
            margin-left: 4rem;
            filter:      $global-drop-shadow;
        }
    }
}

@media screen and (max-width: (#{map-get($breakpoints, 'md')}) - 1px) {
    .login {
        &__form {
            max-width:none;
            left: auto;
            transform:  translate(0, -50%);
            width: auto;
            margin: 0 1rem;
        }
    }
}
