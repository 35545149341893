////
/// Styling for buttons
/// @group components
/// @author Kristian Cox
////

.button {
    cursor:        pointer;
    background:    $color-primary;
    color:         #FFFFFF;
    display:       inline-block;
    font-size:     1rem;
    line-height:   1rem;
    border:        0;
    padding:       0.5rem 1.5rem;
    text-align:    center;
    box-shadow:    0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition:    box-shadow 0.3s cubic-bezier(.25,.8,.25,1),
                   background 0.3s ease-in-out;
    outline:       none;

    &--block {
        display: block;
    }

    &--no-padding {
        padding: 0.5rem;
    }

    &:hover {
        z-index:    10;
        box-shadow: 0 7px 14px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22);
        background: lighten($color-primary, 5%);
        text-decoration: none;
    }

    &:active {
        background:    $color-primary;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }

    &:visited {
        color: #FFFFFF;
    }

    &--success {
        background-color: $color-success;

        &,
        &:visited {
            color: #FFFFFF;
        }

        &:hover {
            background-color: lighten($color-success, 5%);
        }

        &:active {
            background-color: $color-success;
        }
    }

    &--danger {
        background: $color-danger;
        color:      #FFFFFF;
        &:hover {
            background: lighten($color-danger, 5%);
        }

        &:active {
            background: $color-danger;
        }

        &:visited {
            color: #FFFFFF;
        }
    }

    &--disabled {
        background:     #ccc;
        color:          #666;
        pointer-events: none;

        &:hover {
            background: #ccc;
        }

        &:active {
            background: #ccc;
        }

        &:visited {
            color: #ccc;
        }
    }


    &--link {
        padding: 0;

        &,
        &:hover {
            background-color: transparent;
            box-shadow: none;
        }

        &:hover {
            text-decoration: underline;
        }

        &.button--danger {
            color: $color-danger;
        }

        &.button--success {
            color: $color-success;
        }
    }
}
