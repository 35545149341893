.modal {
    position: fixed;
    padding: 1rem;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: rgba(#000, .88);
    transition: opacity 300ms linear, visibility 0s 300ms;
    opacity: 0;
    visibility: hidden;
    text-align: center;
    overflow: hidden;

    &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
        overflow: auto;
        backface-visibility: hidden;
        -webkit-overflow-scrolling: touch;
    }

    &::before {
        content: "";
        vertical-align: middle;
        line-height: 100%;
        height: 100%;
        display: inline-block;
        margin-left: -0.3em;
    }

    &__body {
        background-color: #fff;
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        position: relative;
        width: 100%;
        max-width: 640px;
        padding: 1.5rem 1.5rem 1px;
    }
}
