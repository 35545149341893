////
/// Styling for badges
/// @group components
/// @author Kristian Cox
////

.badge {
    box-sizing:    border-box;
    box-shadow:    0 0 0 0.25rem $color-primary;
    margin-left:   0.25rem;
    font-size:     0.75rem;
    background:    $color-primary;
    color:         #FFFFFF;
    display:       inline-block;
    line-height:   0.5rem;
    border-radius: 1rem;
    text-align:    center;
}
