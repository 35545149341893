////
/// Styling for faux tabs
/// @group components
/// @author Kristian Cox
////

.faux-tabs {
    background:      darken($color-light, 10%);
    display:         block;
    width:           100%;
    box-sizing:      border-box;
    margin:          0;
    padding:         0;
    list-style-type: none;
    font-size:       0;

    &__item {
        display: inline-block;

        > a {
            display:         block;
            padding:         1rem;
            font-size:       1rem;
            line-height:     1rem;
            text-decoration: none;
            color:           $color-dark;

            &:visited {
                color: $color-dark;
            }
        }

        &--current {
            > a {
                background: $color-light;
                color:      $color-primary;

                &:visited {
                    color: $color-primary;
                }
            }
        }
    }
}
