////
/// Styling for primary navigation
/// @group components
/// @author Kristian Cox
////

.nav {

    min-width:   230px;
    width:       230px;
    background:  $color-dark;
    flex-basis:  content;
    transition:  all 200ms ease-in-out;
    overflow:    hidden;
    position:    absolute;
    top:         0;
    bottom:      0;
    padding-top: 4.5rem;
    z-index:     90;
    transform:   translateX(-100%);

    &.nav--open {
        transform:  translateX(0%);
    }

    > ul {
        list-style: none;
        text-align: left;
        font-size:  0;
        padding:    0;
        min-width:  160px;
    }

    &__sep {

        width:100%;
        height: 1px;
        background: darken($color-dark, 5%);
    }

    &__item {
        width:   100%;
        display: block;
        @extend  %clearfix;

        a {
            width:           100%;
            box-sizing:      border-box;
            float:           left;
            text-decoration: none;
            text-transform:  capitalize;
            font-size:       1rem;
            color:           #FFFFFF;
            padding:         0 1.5rem;
            display:         block;
            line-height:     3rem;
            &:hover {
                background:  rgba(#FFFFFF, 0.2);
            }
        }

        &--active {
            box-shadow: inset 4px 0 0 0 $color-primary;
            a {
                color: $color-primary;
                &:hover {

                }
            }
        }

        &--disabled {
            opacity: 0.2;
            pointer-events: none;
        }


        .icon {
            height:       1rem;
            width:        1rem;
            margin-right: 0.5rem;
        }

        .nav__label {
            display: inline;
        }

        &--search {
            margin: 0.5em 1.5em;
            width: auto;
        }
    }

    &__parent-container {
        width:    100%;
        @extend   %clearfix;
        position: relative;
    }

    &__child-container {
        @extend     %clearfix;
        width:      100%;
        background: darken($color-dark, 2%);
        max-height: 0;
        overflow: hidden;
        transition: max-height 300ms;

        ul {
            margin: 0;
            padding: 0;
        }

        a {
            padding-left: 3rem;
            font-size: 0.75rem;
        }

        &.is-active {
            max-height: 400px;
        }
    }

    &__toggle {
        float:       right;
        border:      none;
        margin:      0;
        padding:     0;
        width:       3rem;
        height:      3rem;
        line-height: 3rem;
        text-align:  center;
        color:       white;
        user-select: none;
        cursor:      pointer;
        position:    absolute;
        right: 0;
        background:  $color-dark;

        &:hover,
        &:focus {
            outline: none;
            background: lighten($color-dark, 15%);
        }

        &:after {
            content: "+";
        }

        &.is-active {
            &:after {
                content: "-";
            }
        }

        ~ a {
            padding-right: 4.5rem;
        }
    }

    &__toggle-checkbox {
        position: absolute;
        z-index: -1;
        opacity: 0;

        + div + div > ul {
            // max-height: 400px;
            // width:      100%;
            // min-width:  0;
            margin:     0;
            padding:    0;
            overflow: visible;
            display: block;
        }

        &:checked {
            + div .nav__toggle:after {
                content: "+";
            }
            + div + div > ul {
                display: none;
            }

        }
    }

    @include breakpoint('lg') {
        position:    static;
        transform:   translateX(0%);
        z-index:     auto;
        &.nav--open {
            transform:  translateX(0%);
        }
    }
}
