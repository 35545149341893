////
/// Styling for general HTML form elements
/// @group elements
/// @author Kristian Cox
////

input[type=text],
input[type=password],
input[type=url],
input[type=email],
input[type=date],
input[type=tel],
input[type=search],
input[type=number] {
    width:         100%;
    box-sizing:    border-box;
    font-size:     $font-size-base;
    line-height:   1.5;
    padding:       0.25rem 1rem;
    border-radius: $global-border-radius;
    background:    $form-transparency;
    box-shadow:    $global-box-shadow;
    border:        none;
}

input[type=date] {
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        display: none;
    }

    &::-webkit-calendar-picker-indicator {
        color: black;
        transform: scale(0.6);
        padding: 0.25rem;
        display:block;
        margin-right: -17px;
        cursor: pointer;
        z-index:100;

        &:hover {
            background: transparent;
        }
    }
}

textarea {
    width:         100%;
    box-sizing:    border-box;
    font-size:     $font-size-base;
    line-height:   1.5;
    padding:       0.25 1rem;
    border-radius: 3px;
    border:        none;
    background:    $form-transparency;
    box-shadow:    $global-box-shadow;
    resize:        none;
}

select {
    $font-size:    strip-unit($font-size-base);
    $line-height:  1.5;
    $padding:      $line-height * $font-size;
    $padding:      (($padding - $font-size) / 2) + px;

    width:         100%;
    max-width:     100%;
    height:        2rem;
    text-overflow: ellipsis;
    box-sizing:    border-box;
    font-size:     $font-size-base;
    padding:       $padding 1rem;
    border-radius: $global-border-radius;
    background:    $form-transparency;
    box-shadow:    $global-box-shadow;
    border:        none;
    appearance:    none;

    &::-ms-expand {
        display:   none;
    }
}

label {
    font-size:   0.75rem;
    line-height: $line-height-base;
    color:       $color-dark;
}

%label-style {
    font-size:   0.75rem;
    line-height: $line-height-base;
    margin:      0;
    padding:     0;
}

fieldset {
    margin:        0 -1rem 1rem -1rem;
    padding:       0 1rem 1rem 1rem;
    border:        0;
    border-bottom: 2px solid darken($color-light, 10%);

    &:last-child {
        margin-bottom: 0;
        border:        0;
    }
}

legend {
    @extend %h3-style;
}
