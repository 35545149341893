.steps {
    // height:          2rem;
    counter-reset:   step;
    list-style:      none;
    font-size:       0;
    margin:          0;
    padding:         0;
    display:         flex;
    justify-content: space-between;

    &--gutterless {
        margin-left: -1rem;
        margin-right: -1rem;
    }

    &__item {
        // display:  inline-flex;
        flex:     1;
        position: relative;
        padding-top: 2.5rem;
    }

        &__text {
            display:    none;
            font-size:  0.75rem;
            color:      $color-primary;
            text-align: center;
            // position:   absolute;
            // z-index:    100;
            // top:        0.5rem;
            // left:       50%;
            // transform:  translate(-50%, 2rem);
            width:      100%;
        }

    &__item {
        &:before {
            content:           counter(step);
            counter-increment: step;
            width:             2rem;
            height:            2rem;
            display:           block;
            background:        $color-light;
            border:            2px solid $color-primary;
            color:             $color-primary;
            border-radius:     50%;
            text-align:        center;
            margin:            0 auto;
            z-index:           10;
            line-height: 2rem;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1;
        }

        &:after {
            content:    "";
            width:      100%;
            height:     2px;
            background: $color-primary;
            position:   absolute;
            top:        1rem;
            line-height: 2rem;
        }

        &--active {
            &:before {
                background: $color-primary;
                color:      white;
            }
        }

        &--invalid {
            &:before {
                border-color: $color-danger;
                color: $color-danger;
            }

            &.steps__item--active {
                &:before {
                    background-color: $color-danger;
                    color: #fff;
                }
            }

            .steps__text {
                color: $color-danger;
            }
        }
    }

    @include breakpoint('md') {
        // height:        4rem;
        margin-bottom: 1rem;

        &__text {
            display: block;
        }
    }

    &__summary {
        background-color: #fff;
        display: inline-block;
        font-size: 0.75rem;
        padding: 4px 12px;
        color: #333;
    }
}
