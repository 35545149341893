.header {
    height:     4.5rem;
    background: $color-primary;
    padding:    0 2rem;
    box-sizing: border-box;
    color:      #FFFFFF;
    overflow:   hidden;
    position:   absolute;
    top:        0;
    left:       0;
    right:      0;
    z-index:    100;
    // box-shadow: $global-box-shadow;

    &__menu-button {
        float:        left;
        margin-right: 1rem;
        padding-top:  1.5rem;
        @include breakpoint('lg') {
            display: none;
        }
        .icon {
            display: block;
            color:   white;
            width:   1.5rem;
            height:  1.5rem;
        }
    }

    h1 {
        font-size:      1rem;
        line-height:    4.5;
        color:          #FFFFFF;
        text-transform: uppercase;
        float:          left;
    }

    &__actions {
        @extend %clearfix;
        float:   right;
        display: inline-block;
        > div {
            float: left;
        }
        a {
            display: block;
            padding: 1.5rem;
            color: white;

            &:hover {
                color: $color-primary;
                background: white;
                text-decoration: none;
            }
        }
        .icon {
            height:       1.25rem;
            width:        1.25rem;
            margin-right: 0.5rem;
            position: relative;
            top: -2px;
        }
    }
}
