////
/// Styling for the zone-list component
/// @group components
/// @author Kristian Cox
////

.zone-list {
    list-style: none;
    margin: 0;
    padding: 1rem 0 0 0;
    font-size: 0;
    display: flex;
    li {
        display: inline-flex;
        flex: 1;
    }

    input[type=radio] {
        display: none;
        + label {
            width: 100%;
            height: 1rem;
            line-height: 1rem;
            display: inline-block;
            padding: 0.5rem;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            background: $color-dark;
            color: white;
        }
        &:checked {
            + label {
                background: $color-primary;
            }
        }
    }
}
