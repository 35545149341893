////
/// Styling for search component
/// @group components
/// @author Kristian Cox
////

.search {
    &__input {
        float: left;
    }

    &__button {
        float: left;
    }
}
