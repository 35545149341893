////
/// Modifies the margin for an element
/// @group trumps
/// @author Kristian Cox
////

.margin-bottom {
    margin-bottom: 1rem;
}

.margin-bottom-clear {
    margin-bottom: 0;
}
