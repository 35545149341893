////
/// Styling for SVG icons
/// @group components
/// @author Kristian Cox
////

.icon {
    fill:           currentColor;
    vertical-align: middle;
    display:        inline-block;

    &--xs {
        width:  0.5rem;
        height: 0.5rem;
    }

    &--sm {
        width:  1rem;
        height: 1rem;
    }

    &--md {
        width:  2rem;
        height: 2rem;
    }

    &--lg {
        width:  3rem;
        height: 3rem;
    }

    &--xl {
        width:  4rem;
        height: 4rem;
    }
}
