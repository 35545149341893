////
/// Styling for registration form
/// @group components
/// @author Kristian Cox
////

.registration {
    min-width:  100vw;
    min-height: 100vh;

    h1 {
        @extend        %h2-style;
        margin-bottom: 0;
        text-align:    center;
        color:         $color-primary;
    }

    &__form {
        position:   absolute;
        box-sizing: border-box;
        box-shadow: $global-box-shadow;
        max-width:  600px;
        left:       50%;
        top:        50%;
        transform:  translate(-50%, -50%);
        background: $color-light;
        padding:    3rem 1rem 1rem 1rem;
    }

    &__logo {
        text-align: center;
        margin-top:  -5rem;

        .icon {
            width:       10rem;
            height:      auto;
            color:       $color-primary;
            margin-left: 4rem;
            margin-bottom: 0;
            filter:      $global-drop-shadow;
        }
    }

    &__step {
        display: none;

        &:first-of-type {
            display: block;
        }
    }

    @media screen and (max-width: (#{map-get($breakpoints, 'md')}) - 1px) {
        &__form {
            max-width: none;
            left:      auto;
            top:       3rem;
            transform: translate(0, 0);
            width:     auto;
            margin:    0 1rem;
        }
    }
}
