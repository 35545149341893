////
/// Styling for flash messages
/// @group components
/// @author Kristian Cox
////

.flash {
    background:    $color-primary;
    padding:       0.5rem 3rem 0.5rem 1rem;
    // display:       flex;
    position: relative;
    border-left:   0.5rem solid darken($color-primary, 10%);
    margin-bottom: 1rem;
    box-shadow:    $global-box-shadow;

    &__message {
        font-size: 1rem;
        color:      #FFFFFF;
    }

    &__close {
        position: absolute;
        right: 1rem;
        top: 0.5rem;
        a {
            color: white;
        }
    }

    &--success {
        background:  $color-success;
        border-left: 0.5rem solid darken($color-success, 10%);
        &__message {
            color: #FFFFFF;
        }

        &__close {
            a {
                color: white;
            }
        }
    }

    &--info {
        background:  $color-info;
        border-left: 0.5rem solid darken($color-info, 10%);
        &__message {
            color: #FFFFFF;
        }

        &__close {
            a {
                color: white;
            }
        }
    }

    &--warning {
        background:  $color-warning;
        border-left: 0.5rem solid darken($color-warning, 10%);
        &__message {
            color: #333333;
        }

        &__close {
            a {
                color: #333333;
            }
        }
    }

    &--danger {
        background:  $color-danger;
        border-left: 0.5rem solid darken($color-danger, 10%);
        &__message {
            color: #FFFFFF;
        }

        &__close {
            a {
                color: white;
            }
        }
    }

    .icon {
        height: 1rem;
        width:  1rem;
    }
}
