////
/// A flexbox based grid system
/// @group objects
/// @author Kristian Cox
////

.grid {
    @extend %clearfix;
    display: block;
    // box-sizing:     border-box;
    // flex:           0 1 auto;
    // flex-direction: row;
    // flex-wrap:      wrap;

    &--gutters {
        margin: 0 -1rem;

        > .grid__col {
            padding:    0 1rem;
            box-sizing: border-box;
        }
    }

    &--space-right {
        padding-right: 2rem;
    }

    &--tall {
        height: 100%;
    }

    .grid__col {
        width: 100%;
        max-width:  100%;
        float: left;

        @each $breakpoint, $size in $breakpoints {
            @include breakpoint($breakpoint) {
                @for $i from 1 through $grid-columns {
                    &--#{$breakpoint}-#{$i} {
                        $percent: (100% / $grid-columns) * $i;
                        width: $percent;
                        max-width: $percent;
                    }
                    &--#{$breakpoint}-offset-#{$i} {
                        $percent: (100% / $grid-columns) * $i;
                        margin-left: $percent;
                    }
                }
            }
        }
    }

    &--reverse {
        > .grid__col {
            float: right;
        }
    }
}
