.list {
    &--bare {
        list-style: none;
        margin:     -5px -5px 0 0;
        padding:    0;
    }
    
    &--flex {
        display: flex;
        flex-wrap: wrap;
    }
    
    &__item {
        margin-top: 5px;
        margin-right: 5px;
    }
}
