////
/// Styling for universal structural elements
/// @group objects
/// @author Kristian Cox
////

.wrapper {
    // max-height: 100vh;
    box-sizing: border-box;
    // bottom:     0;
    // top:        0rem;
    // left:       0;
    // right:      0;
    // position:   absolute;
    background: lighten($color-dark, 5%);
    @include breakpoint('lg') {
        display:    flex;
    }

    &--fill {
        min-height: 100vh;
        display: block;
    }

    &--padded {
        padding: 1rem;
    }
}
