////
/// Styling for specific HTML form elements
/// @group components
/// @author Kristian Cox
////

.form {
    .form__controls {
        @extend %clearfix;

        > button,
        > input {
            float:       right;
            margin-left: 1rem;
        }
    }

    hr {
        margin:  0 -1rem 1rem -1rem;
        border: 1px solid $color-primary;
    }

    &__prefix {
        float:       left;
        line-height: 1rem;

        + input {
            margin-left: -2rem;
        }
    }

    &__field {
        &--error {
            input[type=text],
            input[type=password],
            input[type=url],
            input[type=email],
            input[type=tel],
            input[type=search],
            input[type=number],
            textarea,
            select {
                border-left: 0.25rem solid $color-danger;
            }

            .switch ~ label {
                box-shadow: inset 0 0 0 2px $color-danger, $global-box-shadow-inset !important;
            }
        }

        &--naked {
            border-bottom: 1px dotted $color-dark;
            input[type=text],
            input[type=password],
            input[type=url],
            input[type=email],
            input[type=tel],
            input[type=search],
            input[type=number] {
                line-height:    1rem;
                padding:        0;
                box-shadow:     none;
                background:     transparent;
                vertical-align: top;

                &:focus {
                    outline: 1px;
                }
            }
        }

        &--extra {
            input[type=checkbox] {
                display:      none;
                float:        right;
                padding-left: 0.5rem;

                &:checked {
                    + label > svg {
                        color:      white;
                        border:     1px solid $color-dark;
                        background: $color-dark;

                    }
                }

                + label {
                    line-height:    1rem;
                    font-size:      1rem;
                    vertical-align: middle;
                    margin-top:     -0.5rem;
                    display:        inline-block;

                    > svg {
                        background:    transparent;
                        font-size:     1rem;
                        width:         1rem;
                        height:        1rem;
                        color:         rgba($color-dark, 0.25);
                        border:        1px solid rgba($color-dark, 0.25);
                        cursor:        pointer;
                        border-radius: 50%;
                        box-sizing:    border-box;
                    }
                }
            }
        }
    }

    &__feedback {
        background-color: $color-danger;
        color: #fff;
        padding: 8px 12px;
        font-size: 0.75rem;

        &:empty {
            display: none;
        }
    }
}
