////
/// Styling for the alphabetial separators
/// @group components
/// @author Kristian Cox
////
.alpha-sep {
    display: inline-block;
    width: 100%;
    height: 3rem;
    vertical-align: middle;
    color: white;
    font-size: 3rem;
    line-height: 3rem;
    padding-bottom: 2rem;
    position: relative;

    &:after {
        position: absolute;
        content: '';
        height: 2px;
        background: white;
        width: 100%;
        z-index: 1;
        left: 0;
        bottom: 1.25rem;
    }
}
