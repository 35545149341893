////
/// Basic stylings for html and body
/// @group elements
/// @author Kristian Cox
////

html,
body {
    height: 100%;
    min-height: 100%;
}
