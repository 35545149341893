////
/// Styling for an alphabetic side menu
/// @group components
/// @author Kristian Cox
////

.alpha-menu {
    list-style: none;
    margin: 0;
    padding: 2rem 0;
    display: block;
    height: 100vh;
    position: fixed;
    right: 2rem;
    top: 2rem;
    box-sizing: border-box;

    li {
        display: block;
        height: (100 / 26) + %;
        box-sizing: border-box;
    }

    &__item {
        &--disabled {
            color: lighten($color-dark, 15%);
            pointer-events: none;
        }
    }
}
