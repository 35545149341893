////
/// Styling for tables
/// @group components
/// @author Kristian Cox
////

.table {
    background:      #FFFFFF;
    width:           100%;
    box-shadow:      $global-box-shadow;
    border-collapse: collapse;
    border:          none;
    border-spacing:  0px;
    margin:          0;

    thead,
    tfoot {
        background:  $color-primary;
        th {
            font-weight: bold;
            color:       white;
            border:      none;
            padding:     1rem 0 1rem 1rem;
            text-align:  left;
            line-height: 1rem;

            &:last-child {
                padding-right: 1rem;
            }
        }
    }

    tbody {
        td {
            border:         none;
            padding:        1rem 0 1rem 1rem;
            text-align:     left;
            // line-height:    1rem;
            vertical-align: top;

            &:last-child {
                padding-right: 1rem;
            }

            &:hover {
                .table__controls {
                    opacity: 1;
                }
            }
        }
    }

    &--striped {
        tbody {
            tr:nth-child(odd) {
                background: darken(white, 4%);
            }
        }
    }

    &--horizontal {
        tbody {
            tr td {
                text-align: right;
            }

            tr th {
                background:  $color-primary;
                color:       white;
                border:      none;
                padding:     1rem;
                text-align:  left;
                line-height: 1rem;
                text-align:  left;
                width:       1%;
                white-space: nowrap;
                font-weight: normal;
                font-size:   0.8rem;
            }

            tr td:last-child {
                width:         100%;
                text-overflow: ellipsis;
                white-space:   nowrap;
                overflow:      hidden;
            }

            tr:nth-child(odd) {
                th {
                    background:  darken($color-primary, 4%);
                }
            }

            tr td.table__divider {
                @extend %h3-style;
                font-size:  1rem;
                text-align: left;
                color:      #666;
                background: #d9d9d9;
            }
        }
    }

    &__controls {
        width:     100%;
        display:   inline-block;
        font-size: 0.8rem;

        a {
            text-decoration: none;
            font-size:       inherit;
            color:           $color-success;

            &:hover {
                text-decoration: underline;
            }
        }

        a:nth-child(n+0):last-child {
            color: $color-customer;
        }
    }

    &__roles {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__role {
        font-size:  0.75rem;
        color:      #FFFFFF;
        text-align: center;
        line-height: 1rem;

        &--developer {
            background: $color-developer;
        }

        &--super {
            background: $color-super-admin;
        }

        &--admin {
            background: $color-admin;
        }

        &--customer {
            background: $color-customer;
        }

        &--import {
            background: darken($color-customer, 10%);
        }

        &--export {
            background: darken($color-customer, 10%);
        }

        &--european {
            background: darken($color-customer, 10%);
        }
    }

    &__form {
        display: inline;
        font-size: inherit;

        button {
            font-size:   inherit;
            display:     inline;
            background:  none;
            border:      none;
            padding:     0;
            margin:      0;
            line-height: 1rem;
            color:       $color-danger;
            cursor:      pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__minimal-heading {

    }

    &__minimal-content {
        padding: 1rem !important;

        > ul
        {
            margin:     0;
            padding:    0;
            list-style: none;
            > li {
                text-align:    right;
                width:         100%;
                text-overflow: ellipsis;
                white-space:   nowrap;
                overflow:      hidden;
                strong {
                    float: left;
                    padding-right: 1rem;
                }
            }
        }
    }

    @media screen and (max-width: (#{map-get($breakpoints, 'md')}) - 1px) {
        &--responsive {
            table-layout: fixed;
            th,
            td {
                display: none;
            }
        }

        .show-mobile {
            display: table-cell;
        }

        th.table__minimal-heading {
            display: table-cell;
        }

        td.table__minimal-content {
            display: table-cell;
        }

        &__controls {
            opacity: 1;
        }
    }

    @include breakpoint('md') {
        th.table__minimal-heading {
            display: none;
        }

        td.table__minimal-content {
            display: none;
        }
    }
}
