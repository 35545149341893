.masthead {
    background-color: #fff;
    padding: 16px;
    height: 62px;
    position: relative;
}

    .masthead__logo {
        display: inline-block;
        
        img {
            display: block;
            width: auto;
            height: 62px;
        }
    }

    .masthead__title {
        font-family: "Gotham Rounded", "Gotham Rounded A", "Gotham Rounded B", sans-serif;
        font-weight: 100;
        font-size: 24px;
        color: $color-primary;
        line-height: 1;
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        
        @media (max-width: 639px) {
            display: none;
        }
    }
