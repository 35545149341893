////
/// Styling for non-standard inputs
/// @group components
/// @author Kristian Cox
////

.switch {
    display: none;

    ~ label {
        width:         3rem;
        height:        1.5rem;
        border-radius: 0.75rem;
        background:    darken($color-light, 10%);
        display:       block;
        position:      relative;
        font-size:     0;
        transition:    all 200ms ease-in-out;
        cursor:        pointer;
        box-shadow:    $global-box-shadow-inset;

        &:after {
            width:         1rem;
            height:        1rem;
            display:       block;
            border-radius: 50%;
            background:    white;
            content:       "";
            position:      absolute;
            top:           0.25rem;
            left:          0.25rem;
            transform:     translateX(0);
            transition:    all 200ms ease-in-out;
            box-shadow:    $global-box-shadow;
        }
    }

    &:checked {
        ~ label {
            background: $color-primary;
            &:after {
                transform: translateX(1.5rem);
            }
        }
    }

    &__label {
        @extend %label-style;
    }
}

.select-wrapper {
    position: relative;
    &:after {
        content:        '▾';
        width:          0.5rem;
        height:         0.5rem;
        color:          black;
        pointer-events: none;
        position:       absolute;
        z-index:        10;
        top:            0.25rem;
        right:          0.5rem;
        font-size:      0.75rem;
    }
}
