////
/// Site-wide colour variables
/// @group settings
/// @author Kristian Cox
////

$color-primary:     #f47a20;
$color-secondary:   #346BB2;
$color-tertiary:    #C4CC36;

$color-light:       #F2F2F2;
$color-dark:        #333333;

$color-default:     #FFFFFF;
$color-success:     #65ad56;
$color-info:        #4893DB;
$color-warning:     #FFA726;
$color-danger:      #EF5350;

$color-developer:   $color-success;
$color-super-admin: darken($color-danger, 20%);
$color-admin:       $color-danger;
$color-customer:    $color-info;
