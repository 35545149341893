////
/// Global settings related to universal styles, e.g border-radius
/// @group settings
/// @author Kristian Cox
////

$global-border-radius:    0px;
$global-box-shadow:       0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$global-box-shadow-inset: inset 0 1px 3px rgba(0,0,0,0.12), inset 0 1px 2px rgba(0,0,0,0.24);
$global-drop-shadow:      drop-shadow(0 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0 1px 2px rgba(0, 0, 0, 0.24));
