////
/// Styling for an accordians
/// @group components
/// @author Kristian Cox
////

.accordian {
    width: 100%;
    @extend %clearfix;

    &__item {
        @extend %clearfix;
        &:first-child {
            > label {
                border-top: 1px solid darken($color-light, 10%);
            }
        }
    }

    &__input {
        display: none;

        + label {
            padding:       1rem;
            font-size:     1rem;
            background:    $color-light;
            color:         $color-dark;
            cursor:        pointer;
            width:         100%;
            display:       block;
            float:         left;
            box-sizing:    border-box;
            border-bottom: 1px solid darken($color-light, 10%);

            &:after {
                display:        block;
                content:        '+';
                width:          1rem;
                height:         1rem;
                font-size:      1rem;
                pointer-events: none;
                float:          right;
            }
        }

        ~ .accordian__content {
            background: darken($color-light, 10%);
            width:      100%;
            float:      left;
            height:     auto;
            max-height: 0px;
            overflow:   hidden;
            box-sizing: border-box;
            // transition: max-height 200ms linear;

            > div {
                padding: 1rem;
            }
        }

        &:checked + label {
            color:      white;
            background: $color-primary;

            &:after {
                content:        '-';
                pointer-events: none;
            }
        }

        &:checked ~ .accordian__content {
            max-height: 2000px;
        }
    }

    &__content {

    }
}
