////
/// Styling for registration form
/// @group components
/// @author Kristian Cox
////

.review-wizard {
    &__section {
        display: none;

        &:first-of-type {
            display: block;
        }
    }
}
